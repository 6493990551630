<template>
  <div style="background-color: #eff3f5;min-height: 100vh;width: 100%;overflow: scroll;padding: 50px 20px;" align="center" overflow="scroll">
    <a-card style="background-color: white;width: 1100px;padding: 0 20px;">
      <div style="margin-top: 20px">
        <h3>
          <p style="font-size: 26px">{{ agreementInfo.agreementName }}</p>
        </h3>
      </div>
      <div align="left" style="margin-top: 50px" v-html="agreementInfo.agreementContent" />
    </a-card>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { _user } from '@/api'

export default {
  name: 'Agreement',
  components: {
  },
  setup() {
    const agreementInfo = ref({
      agreementContent: '',
      agreementName: ''
    })
    const route = useRoute()
    const getSaasMerAgreement = async () => {
      const res = await _user.getSaasMerAgreement({ id: route.params.key })
      if (res.data.succ) {
        agreementInfo.value = res.data.data
        document.title = agreementInfo.value.agreementName
      }
    }

    route.params.key && getSaasMerAgreement()

    return {
      agreementInfo, route, getSaasMerAgreement
    }
  }
}

</script>

<style lang='less' scoped></style>
